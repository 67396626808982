@import "styles/settings";

body{
    background: #FAFAFA;

    @include breakpoint('md'){
        overflow-x: hidden;
        //overflow-y: hidden;
    }

}
