@import "../styles/settings";

.form-cards__gird{

    display: flex;
    flex-wrap: wrap;
    margin: 4rem 0 0;
    width: 100%;
    padding: 2rem 0;


    @include breakpoint('md'){
        //margin: auto;
        //position: absolute;
        //width: 95vw;
        //max-width: 1200px;
        //top: 50%;
        //left: 50%;
        //opacity: 0;
        //transform: translate(-50%,-45%);
        //animation: form-cards__fadein 300ms 150ms forwards;

        margin: auto;
        width: 1200px;
        max-width: 95%;
        padding: 5rem 0 10rem;
        /* top: 50%; */
        /* left: 50%; */
        /* opacity: 0; */
        /* transform: translate(-50%, -45%); */
        /* animation: form-cards__fadein 300ms 150ms forwards; */
    }

}

@keyframes form-cards__fadein {
    from{
        opacity: 0;
        transform: translate(-50%,-45%);
    }
    to{
        opacity: 1;
        transform: translate(-50%,-55%);
    }
}


.form-cards__gird--hide .card{
    opacity: 0;
    transform: translateY(10px);
    transition: none;
}

.form-cards__gird--show .card{
    opacity: 1;
    transform: translateY(0);
}


.form-cards__set-switch{


    display: flex;
    align-items: center;
    z-index: 10;

    background: $red;
    padding: 1rem;

    @include breakpoint('xs'){
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    @include breakpoint('md'){
        position: fixed;
        left: 10vw;
        bottom: 10px;
        background: transparent;
        padding: 0;
    }

}

.form-cards__set-switch__title{
    font-family: $font-heading;
    font-size: 25px;
    color: #FFFFFF;
    letter-spacing: 0.89px;
    margin-right: 20px;

    @include breakpoint('xs'){
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.form-cards__set-switch__set{
    position: relative;
    opacity: 0.5;
    transform: translateY(20px);
    animation: form-cards__set-switch__set_fade 200ms forwards;
    transition: all 200ms;

    border: 3px solid #FFFFFF;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;

    @include breakpoint('xs'){
        margin-bottom: 10px;
    }

}

.form-cards__set-switch__click{
    padding: 6px 8px;
}

.form-cards__set-switch__set--active, .form-cards__set-switch__set:hover{
    opacity: 1;
}

.form-cards__set-switch__set span{
    font-family: $font-heading;
    font-size: 25px;
    color: #FFFFFF;
    letter-spacing: 0.89px;

}

.form-cards__set-switch__add{
    border: 3px solid #FFFFFF;
    border-radius: 5px;
    padding: 6px 8px;
    font-family: $font-heading;
    font-size: 25px;
    background: white;
    color: #E2231A;
    letter-spacing: 0.89px;
    cursor: pointer;
    transition: all 200ms;
    transform: translate(0);

    @include breakpoint('xs'){
        margin-bottom: 10px;
    }
}

.form-cards__set-switch__add:hover{
    box-shadow: 0 2px 4px 0 rgba(111,8,8,0.80);
    transform: translateY(-2px);
}


@keyframes form-cards__set-switch__set_fade {
    from{
        transform: translateY(20px);
    }
    to{
        transform: translateY(0);
    }
}


.form-cards__set-switch__set__delete{
    position: absolute;
    top: -7px;
    right: -7px;
    color: #E2231A;
    font-family: $font-heading;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background: white url("../img/cross.png") no-repeat center center / 7px 7px;

    opacity: 0;
    transform: translateY(-5px);
    transition: all 200ms 0ms;
}

.form-cards__set-switch__set:hover .form-cards__set-switch__set__delete{
    opacity: 1;
    transform: translateY(0);
    transition: all 150ms 300ms;
}

.form-cards__set-switch__set:nth-child(2) .form-cards__set-switch__set__delete{
    opacity: 0!important;
}


.form-cards__price{
    position: fixed;
    right: calc(10vw + 150px);
    bottom: 20px;
    color: white;
    z-index: 10;

    font-size: 30px;
    font-weight: bold;

    @include breakpoint('xs'){
        position: fixed;
        font-size: 25px;
        left: 1rem;
        bottom: 22px;
        right: unset;
    }
}

.form-cards__price small{
    font-size: 15px;
    font-weight: normal;
    margin-right: 10px;
}

.form-cards__set-switch__addedprice{
    margin-left: 10px;
    color: white;
    font-size: 20px;
    font-weight: bold;
}




.explain-overlay{

    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(255,255,255,0.95);
    //background: url("../img/explain.png") no-repeat 42% 35% / 800px rgba(255,255,255,0.9);

    &.-step1{
        background: url("../img/explain.png") no-repeat center / 95% rgba(255,255,255,0.9);
        @include breakpoint("md"){
            background: url("../img/explain.png") no-repeat 43% 35% / auto 70% rgba(255,255,255,0.9);
        }
    }

    &.-step2{
        background: url("../img/explain2.png") no-repeat center / 95% rgba(255,255,255,0.9);
        @include breakpoint("md"){
            background: url("../img/explain2.png") no-repeat 53% 40% / auto 30% rgba(255,255,255,0.9);
        }
    }


    .explain-overlay__close{

        position: absolute;
        bottom: 10rem;
        right: 15%;
        //transform: translate(-50%, 0);

        outline: none;
        background: #E2231A;
        border: 3px solid #E2231A;

        font-family: $font-heading;
        color: #FFFFFF;
        letter-spacing: 0.89px;
        text-transform: uppercase;

        cursor: pointer;

        padding: 6px 15px;
        //margin-bottom: 10px;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);

        font-size: 20px;
        @include breakpoint("md"){
            font-size: 25px;
        }
    }

}


.form-cards__help{
    position: absolute;

    height: 2rem;
    width: 2rem;
    cursor: pointer;

    background: url("../img/help.png") no-repeat center center / contain;

    top: 1rem;
    right: 1rem;

    @include breakpoint("md"){
        top: 2rem;
        right: 2rem;
    }

}


.confirmation__popup{

    position: fixed;

    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    padding: 1.5rem;
    border-radius: 10px;

    background: #E2231A;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);

    .confirmation__message{
        color: white;
        margin: 0rem auto 1.5rem;
        max-width: 300px;
        text-align: center;
        font-size: 1.2rem;

        //font-weight: bold;


    }

    .buttons{

        display: flex;
        margin: auto;
        justify-content: center;
        max-width: 50%;

        button{
            outline: none;
            background: #FFFFFF;
            border: 3px solid #FFFFFF;

            font-family: $font-heading;
            font-size: 1rem;
            color: #E2231A;
            //letter-spacing: 0.89px;
            text-transform: uppercase;

            cursor: pointer;

            padding: 6px 15px;
            margin: 0 10px;

            transition: all 200ms;
            transform: translate(0);

            &:first-child{
                background: transparent;
                color: white;
            }
        }

    }
}
