@import "../styles/settings";

.form-contact{

    padding: 4rem 1rem 50px;


    @include breakpoint('md'){
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 80vw;
        width: 100%;
        opacity: 0;
        padding: 0;

        animation: form-contact__fadein 300ms 150ms forwards;
    }

    @media (min-width: 1000px) and (max-height: 630px) {

        position: relative;
        padding: 5rem 0 10rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        top: 0;
        left: 0;

    }

}

@keyframes form-contact__fadein {
    from{
        opacity: 0;
        transform: translate(-50%,-50%);
    }
    to{
        opacity: 1;
        transform: translate(-50%,-55%);
    }
}


.form-contact__title{
    @include headFontSmall;
    color: #000000;
    text-align: center;
    margin: 30px 0 50px;
}


.form-contact__inner{


    display: flex;
    flex-wrap: wrap;

}


.form-contact__inner label{
    @include bodyFont;
    color: #999999;
}

.form-contact__inner input{


    border: none;
    display: block;
    width: calc(100% - 40px);
    outline: none;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(163,163,163,0.10);

    @include bodyFont;
    color: #232323;

    padding: 20px 20px;
    margin: 5px 0 20px;

}

.form-contact__inner_full{
    margin: 5px 20px 0;
    width: 100%;

    @include breakpoint('sm'){
        width: calc(100% - 40px);
    }
}

.form-contact__inner_half{
    width: 100%;
    margin: 5px 20px 0;

    @include breakpoint('sm'){
        width: calc(50% - 40px);
    }

}


.form-contact__inner_big{
    width: 100%;
    margin: 5px 20px 0;
    @include breakpoint('sm') {
        width: calc(80% - 40px);
    }

}

.form-contact__inner_small{
    width: calc(50% - 40px);
    @include breakpoint('sm') {
        width: calc(20% - 40px);
    }
    margin: 5px 20px 0;

}
