@import "../styles/settings";


.error{
    position: fixed;
    top: 50px;
    max-width: 500px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 1rem;
    background: #E2231A;
    color: white;
    box-shadow: 0 0 5px rgba(0,0,0,0.1), 0 0 70px rgba(0,0,0,0.05);

    opacity: 0;

    animation: errorAnim 300ms 150ms forwards;

    z-index: 99999;
}

@keyframes errorAnim {
    from{
        opacity: 0;
        top: 30px
    }
    to{
        opacity: 1;
        top: 50px;
    }
}
