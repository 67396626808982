@import "breakpoints";

$font-heading: 'Roboto Condensed', sans-serif;
$font-body: 'Roboto', sans-serif;

$black: #232323;
$red: #e2231a;
$white: #fff;
$gray: #999999;



@mixin bodyFont {
  font-family: $font-body;
  font-size: 18px;
  line-height: 1.6;

  @include breakpoint(md){
    font-size: 20px;
    letter-spacing: 0.89px;

    line-height: 1.8;
  }
}

@mixin headFont {
  font-family: $font-heading;
  letter-spacing: 2px;
  font-size: 32px;

  @include breakpoint(sm) {
    font-size: 40px;
  }

  @include breakpoint(md) {
    font-size: 75px;
  }
}

@mixin headFontSmall {
  font-family: $font-heading;
  letter-spacing: 1px;
  font-size: 28px;

  @include breakpoint(sm) {
    font-size: 30px;
  }

  @include breakpoint(md) {
    font-size: 35px;
  }
}
