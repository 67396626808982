@import "../styles/settings";

.form-mail{

    padding: 4rem 1rem 0;
    min-height: calc(100vh - 4rem - 76px);


    @include breakpoint('md'){
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%,-55%);
        opacity: 0;
        min-height: 0;

        animation: form-mail__fadein 300ms 150ms forwards;
        padding: 0;
    }

    @media (min-width: 1000px) and (max-height: 450px) {

        position: relative;
        max-width: 650px;
        padding: 5rem 0 10rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        top: 0;
        left: 0;

    }

}

@keyframes form-mail__fadein {
    from{
        opacity: 0;
        transform: translate(-50%,-55%);
    }
    to{
        opacity: 1;
        transform: translate(-50%,-60%);
    }
}

.form-mail__input{

    outline: none;
    border: none;

    display: block;
    box-sizing : border-box;
    width: 100%;
    max-width: 800px;


    background: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(80,80,80,0.10);

    text-align: left;
    padding: 20px;

    @include bodyFont;
    color: $black;



    transition: all 300ms;
}


.form-mail__input:focus{
    box-shadow: 0 0 10px 0 rgba(80,80,80,0.20);
}


.form-mail__input--valid{
    border: 1px solid rgba(20,154,69,0.50);
}


.form-mail__helper{
    max-width: 650px;
    margin: auto;
}

.form-mail__helper h1{
    @include headFont;
    color: $black;
    text-align: center;
    margin-bottom: -10px;
}


.form-mail__helper p{
    @include bodyFont;
    color: $gray;
    text-align: center;
    margin-bottom: 50px;
}
