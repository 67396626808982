@import "../styles/settings";

.card{
    position: relative;

    background: white;
    border-radius: 15px;
    padding-top: 25%;
    width: 19%;
    margin: 0.5%;

    box-shadow: 0 0 10px 0 rgba(163,163,163,0.10);


    transform: translateY(0);
    transition: all 300ms;

    @include breakpoint('xs'){
        width: 45%;
        margin: 2.5%;
        padding-top: 66%;
    }

    @include breakpoint('sm'){
        width: calc(100%/3 - 1%);
        margin: 0.5%;
        padding-top: 50%;
    }

    @include breakpoint('md'){
        padding-top: 28%;
        width: 19%;
        margin: 0.5%;
    }

    @include breakpoint('lg'){
        padding-top: 28%;
        width: 19%;
        margin: 0.5%;
    }

}

.card:nth-child(2){transition-delay: 20ms;}
.card:nth-child(3){transition-delay: 40ms;}
.card:nth-child(4){transition-delay: 60ms;}
.card:nth-child(5){transition-delay: 80ms;}
.card:nth-child(6){transition-delay: 100ms;}
.card:nth-child(7){transition-delay: 120ms;}
.card:nth-child(8){transition-delay: 140ms;}
.card:nth-child(9){transition-delay: 160ms;}
.card:nth-child(10){transition-delay: 180ms;}

.card__input{
    font-family: $font-heading;
    color: #E2231A;

    position: absolute;

    border: none;
    outline: none;
    resize: none;
    background: none;
    overflow: hidden;

    height: calc(100% - 110px);
    width: calc(100% - 40px);

    top: 20px;
    left: 20px;
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: 0.64px;

    @include breakpoint('xs'){
        font-size: 1.1rem;
        top: 1rem;
        left: 1rem;
        height: calc(100% - 85px);
        width: calc(100% - 2rem);
    }

    @include breakpoint('sm'){
        font-size: 1.7rem;
    }

    @include breakpoint('md'){
        font-size: 1.2rem;
        top: 1rem;
        left: 1rem;
    }

    @include breakpoint('lg'){
        font-size: 1.5rem;
        top: 20px;
        left: 20px;
        height: calc(100% - 90px);
        width: calc(100% - 40px);
    }
}

.card__input::placeholder {
    /*font-family: HelveticaNeueLTStd-Roman;
    font-size: 20px;
    color: #232323;
    letter-spacing: 0.89px;*/
}


.card__name{

    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 6px;
    left: 0;


}

.card__name input{
    outline: none;
    background: none;
    border: none;
    text-align: center;
    width: 100%;

    font-family: $font-body;
    font-size: 11px;
    color: #E2231A;
    letter-spacing: 0.32px;
}

.card__logo{
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);

}

.card__logo img{
    width: 55px;


    @include breakpoint('xs'){
        width: 40px;
        margin-left: 2px;
    }

    @include breakpoint('md'){
        width: 55px;
        margin-left: 4px;
    }
}
