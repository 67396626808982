@import "../styles/settings";

footer{

    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #E2231A;

    color: white;
    padding: 15px 0 5px;

    min-height: 56px;

    @include breakpoint('md'){
        position: fixed;
    }

}



.footer__inner{

    max-width: 80vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    font-family: $font-heading;
    font-size: 25px;
    color: #FFFFFF;
    letter-spacing: 0.89px;
    /*text-transform: uppercase;*/

    min-height: 50px;


}

.footer__inner__left{
    @include breakpoint('xs'){
        opacity: 0;
    }
}

.footer__inner__right{
    align-self: flex-end;
}

.footer__inner button{

    outline: none;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;

    font-family: $font-heading;
    font-size: 25px;
    color: #E2231A;
    letter-spacing: 0.89px;
    text-transform: uppercase;

    cursor: pointer;

    padding: 6px 15px;
    margin-bottom: 10px;

    transition: all 200ms;
    transform: translate(0);
}

.footer__inner button:hover{
    box-shadow: 0 2px 4px 0 rgba(111,8,8,0.80);
    transform: translateY(-2px);
}


.confirmation__popup{

    position: fixed;

    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    padding: 1.5rem;
    border-radius: 10px;

    background: #E2231A;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);

    .confirmation__message{
        color: white;
        margin: 0rem auto 1.5rem;
        max-width: 300px;
        text-align: center;
        font-size: 1.2rem;

        //font-weight: bold;


    }

    .buttons{

        display: flex;
        margin: auto;
        justify-content: center;
        max-width: 50%;

        button{
            outline: none;
            background: #FFFFFF;
            border: 3px solid #FFFFFF;

            font-family: $font-heading;
            font-size: 1rem;
            color: #E2231A;
            //letter-spacing: 0.89px;
            text-transform: uppercase;

            cursor: pointer;

            padding: 6px 15px;
            margin: 0 10px;

            transition: all 200ms;
            transform: translate(0);

            &:first-child{
                background: transparent;
                color: white;
            }
        }

    }
}
