@import "../styles/settings";

.form-code{


    opacity: 0;

    animation: form-code__fadein 300ms 150ms forwards;

    position: relative;

    @include breakpoint("xs") {
        padding: 0 1rem;
        margin-top: 180px;
    }

    @include breakpoint("sm") {

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%,-55%);

    }

    @media (min-width: 1000px) and (max-height: 650px) {

        position: relative;
        padding: 10rem 0 5rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        max-width: 1000px;
        top: 0;
        left: 0;

    }

}


@keyframes form-code__fadein {
    from{
        opacity: 0;
        @include breakpoint("sm") {
            transform: translate(-50%, -55%);
        }
    }
    to{
        opacity: 1;
        @include breakpoint("sm") {
            transform: translate(-50%,-60%);
        }
    }
}


.form-code__group{

    @include breakpoint(sm) {
        display: flex;
    }
}

.form-code__group button{

    @include bodyFont;
    padding: 1.5rem 1.5rem;
    background: $red;
    color: $white;
    text-align: center;
    outline: none;
    border: none;

    cursor: not-allowed;
    opacity: 0.5;

    transition: all 300ms;

    @include breakpoint(xs) {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem;
    }
}

.form-code__button--valid{
    opacity: 1!important;
    cursor: pointer!important;
}

.form-code__input{

    outline: none;
    border: none;

    display: block;
    box-sizing : border-box;
    width: 100%;
    max-width: 850px;


    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0 0 6px 0 rgba(80,80,80,0.10);

    text-align: left;
    padding: 20px;

    @include bodyFont;
    color: $black;

    transition: all 300ms;

    @include breakpoint('md'){
        min-width: 800px;
    }

}


.form-code__input:focus{
    box-shadow: 0 0 10px 0 rgba(80,80,80,0.20);
}


.form-code__input--valid{
    border: 1px solid rgba(20,154,69,0.50);
}

.form-code__helper{
    max-width: 800px;
    margin: auto;
}

.form-code__helper h1{
    @include headFont;

    color: $black;
    text-align: center;
    margin-bottom: 1rem;
    max-width: 800px;
}

.form-code__helper h1 span{
    text-decoration: underline;
}


.form-code__helper p{
    color: $gray;
    text-align: center;
    //margin-bottom: 30px;
    margin: 0 auto 30px;
    max-width: 650px;

    @include bodyFont;

    a{
        color: $gray;
        text-decoration: underline;

        &:hover{
            color: $gray;
            //font-weight: bold;
        }
    }
}


.form-code__valid, .form-code__haveToCheckEmail{

    width: 100%;
    font-size: 1rem;
    margin-top: 1rem;

    @include breakpoint("sm"){
        position: absolute;
        bottom: -30px;
        left: 0;
        font-size: 0.8rem;
        margin-top: 0;
    }
}

.form-code__valid{
    color: rgba(20,154,69,0.50);
}

.form-code__haveToCheckEmail{
    color: orange;
    cursor: pointer;

    span{
        text-decoration: underline;
        font-weight: bold;
    }
}


.support-link{
    color: #999999;
    @include bodyFont;
    font-size: 16px !important;
    text-decoration: none;

    padding: 1rem 1rem;
    display: block;

    @include breakpoint("sm") {
        position: fixed;
        bottom: 2rem;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 0;
    }
}
