@import "../styles/settings";

header{


    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

}


.header__background{

    position: absolute;
    z-index: 100;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 80px;

    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: #E2231A;

    transition: all 300ms 100ms;
    animation: none;

}

.header__background--hover{
    animation: header__background--hover__bounce 300ms ease-in-out;
}

@keyframes header__background--hover__bounce {
    0%{
        width: 100px;
        height: 100px;
    }
    30%{
        width: 102px;
        height: 102px;
    }
    65%{
        width: 94px;
        height: 94px;
    }
    85%{
        width: 98px;
        height: 98px;
    }
    100%{
        width: 100px;
        height: 100px;
    }
}


.header--open .header__background{
    width: 200vh;
    height: 200vh;

    @include breakpoint("md"){
        width: 200vw;
        height: 200vw;
    }
    animation: none;
}


.header__logo{

    position: absolute;
    z-index: 100;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 30px;

    transform: translate(-50%, 0);
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;


    pointer-events: all;

}


.header__logoAddon{
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 130px;
    transform: translate(-50%, 0);

    opacity: 0;
    visibility: hidden;
    transition: all 200ms 150ms;
}

.header--open .header__logoAddon{
    visibility: visible;
    opacity: 1;
}


.header__menu{

    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 1000px) and (max-height: 760px) {

        position: relative;
        padding: 12rem 0 5rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        top: 0;
        left: 0;

    }



}

.header__menu a{
    display: block;
    font-family: $font-heading;
    color: #FFFFFF;
    text-align: center;
    pointer-events: all;


    font-size: 50px;
    letter-spacing: 3px;
    line-height: 2;

    @include breakpoint("md"){
        font-size: 60px;
        letter-spacing: 3.25px;
        line-height: 1.6;
    }

    @include breakpoint("lg"){
        font-size: 75px;
        letter-spacing: 3.75px;
        line-height: 150px;
    }

    text-decoration: none;

    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);

    transition: all 300ms;

}

.header--open .header__menu a{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.header--open .header__menu a:nth-child(1){
    transition-delay: 150ms;
}
.header--open .header__menu a:nth-child(2){
    transition-delay: 225ms;
}
.header--open .header__menu a:nth-child(3){
    transition-delay: 300ms;
}
