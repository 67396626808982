@import "../styles/settings";

.confirmation{

    padding: 180px 1rem 0;

    @include breakpoint('md'){
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%,-50%);
        opacity: 0;
        padding: 0;

        animation: confirmation__fadein 300ms 150ms forwards;
    }

    @media (min-width: 1000px) and (max-height: 660px) {

        position: relative;
        padding: 10rem 0 10rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        top: 0;
        left: 0;

    }


}

@keyframes confirmation__fadein {
    from{
        opacity: 0;
        transform: translate(-50%,-50%);
    }
    to{
        opacity: 1;
        transform: translate(-50%,-55%);
    }
}


.confirmation__text{
    max-width: 700px;
    margin: auto;
}

.confirmation__text h1{
    @include headFont;
    color: $black;
    text-align: center;
    margin-bottom: -10px;
}


.confirmation__text p{
    @include bodyFont;
    color: $gray;
    text-align: center;
    margin-bottom: 1rem;
}


.confirmation__text a{
    color: $gray;
    text-decoration: underline;
    cursor: pointer;
}
