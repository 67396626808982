@import "../styles/settings";

.form-payment{

    padding: 4rem 1rem 0;
    min-height: calc(100vh - 4rem - 76px);

    @include breakpoint('md'){
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 60vw;
        width: 100%;
        opacity: 0;
        padding: 0;
        min-height: 0;

        animation: form-payment__fadein 300ms 150ms forwards;
    }

    @media (min-width: 1000px) and (max-height: 600px) {

        position: relative;
        padding: 5rem 0 10rem;
        transform: none!important;
        animation: none!important;
        opacity: 1;
        margin: auto;
        top: 0;
        left: 0;

    }

}

@keyframes form-payment__fadein {
    from{
        opacity: 0;
        transform: translate(-50%,-50%);
    }
    to{
        opacity: 1;
        transform: translate(-50%,-55%);
    }
}


.form-payment__title{
    margin: 0 auto 50px;
    max-width: 600px;

}

.form-payment__title h1{
    @include headFontSmall;
    color: $black;
    text-align: center;
    margin-bottom: -10px;
}


.form-payment__title p{
    @include bodyFont;
    font-size: 20px;
    color: $gray;
    letter-spacing: 0.8px;
    text-align: center;
    margin-bottom: 50px;
}


.form-payment__inner{


    display: flex;
    flex-wrap: wrap;

}


.form-payment__inner label{
    @include bodyFont;
    color: $gray;
    letter-spacing: 0.64px;
}

.form-payment__inner .StripeElement{


    border: none;
    display: block;
    width: calc(100% - 40px);
    outline: none;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(163,163,163,0.10);
    border-radius: 10px;
    font-family: $font-heading;
    font-size: 25px;
    color: #232323;
    letter-spacing: 0.89px;

    padding: 20px 20px;
    margin: 5px 0 20px;

}

.form-payment__inner_full{

    width: 100%;
    margin: 0 20px 15px;

}

.form-payment__inner_half{

    width: calc(50% - 40px);
    margin: 0 20px;

}


.form-payment__inner_big{

    width: calc(80% - 40px);
    margin: 0 20px;

}

.form-payment__inner_small{

    width: calc(20% - 40px);
    margin: 0 20px;

}

.form-payment__inner_full__pay{

    position: fixed;
    right: 10vw;
    bottom: 6px;

    z-index: 10;

    outline: none;
    background: #FFFFFF;
    border: 3px solid #FFFFFF;

    font-family: $font-heading;
    font-size: 25px;
    color: #E2231A;
    letter-spacing: 0.89px;
    text-transform: uppercase;

    cursor: pointer;

    padding: 6px 15px;
    margin-bottom: 10px;

    transition: all 200ms;
    transform: translate(0);
}


.payment__loading{

    position: fixed;
    top: 0;left: 0;
    right: 0; bottom: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background: rgba(255,255,255,0.8);
    z-index: 9999;

    @include headFontSmall;

}
