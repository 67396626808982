@import "../styles/settings";

.intro{


    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;



}


.intro__background{

    position: absolute;
    z-index: 100;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 80px;

    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: #E2231A;


    transition: all 300ms 150ms;
}


.intro--open .intro__background{

    width: 200vh;
    height: 200vh;

    @include breakpoint("md"){
        width: 200vw;
        height: 200vw;
    }


    left: 50%;
    top: 80px;

    transform: translate(-50%, -50%);
}

.intro__phrase{
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 875px;

    font-family: $font-heading;
    font-size: 75px;
    color: #FFFFFF;
    letter-spacing: 3.75px;
    text-align: center;
    line-height: 100px;

    transition: all 250ms;
    opacity: 1;

    @include breakpoint("xs"){
        font-size: 40px;
        max-width: 90vw;
        line-height: 1.5;
    }

}

.intro__phrase span{
    width: 250px;
    display: inline-block;
    border-bottom: 7px solid white;
    margin-right: 10px;

    @include breakpoint("xs"){
        border-bottom: 4px solid white;
    }
}

.intro__phrase strong{
    border-bottom: 7px solid white;
    @include breakpoint("xs"){
        border-bottom: 4px solid white;
    }
}

.intro--closed .intro__phrase{
    opacity: 0;
}
